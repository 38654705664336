<template>
  <div id="app">
    <b-container fluid>
      <b-row id="nav" align-v="center"> WTSolutions Tools </b-row>
      <b-row id="main" align-v="center" align-h="center">
        <b-list-group class="fw">
          <b-list-group-item href="excel-to-json.html">
            <div>
              <h5>#1 Excel to JSON</h5>
            </div>
            <p>Convert JSON to Excel within Excel, an Excel add-in</p>
          </b-list-group-item>

          <b-list-group-item href="json-to-excel.html">
            <div>
              <h5>#2 JSON to Excel</h5>
            </div>
            <p>Convert Excel to JSON within Excel, an Excel add-in</p>
          </b-list-group-item>

          <b-list-group-item href="gps-en.html">
            <div>
              <h5>#3 Photos to Google Earth</h5>
            </div>
            <p>
              Display geo-tagged photos in Google Earth based on GPS location 
            </p>
          </b-list-group-item>

          <b-list-group-item href="gps.html">
            <div>
              <h5>#4 照片to谷歌地球</h5>
            </div>
            <p>照片导入Google Earth/谷歌地球的图片信息处理软件</p>
          </b-list-group-item>
        </b-list-group>
      </b-row>
      <b-row id="footer" align-v="center" align-h="center">
        Copyrights Reserved 2022 | WTSolutions |
        <a href="contact.html" target="_blank"> Contact </a>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
.fd {
  width: 100%;
}
#nav {
  width: 100%;
  background-color: #3f7fce;
  position: fixed;
  z-index: 999;
  height: 80px;
  color: white;
  font-size: x-large;
  text-indent: 20px;
}

#main {
  min-height: calc(100vh - 50px);
}

#footer {
  height: 20px;
  width: 100%;
}

.b-card {
  width: 500px;
}
</style>
