import Vue from 'vue'
import App from './App.vue'
import { NavbarPlugin } from "bootstrap-vue";
import { LayoutPlugin } from 'bootstrap-vue'
import { ListGroupPlugin } from 'bootstrap-vue'
Vue.use(ListGroupPlugin)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(NavbarPlugin);
Vue.use(LayoutPlugin)
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
